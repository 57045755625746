import moment from 'moment'

const ThumbPartner = '%2F'
export const ThumbPrefix = 'thumb_'

export function changeImageUrl(source, pattern) {
  let pos = source.lastIndexOf(ThumbPartner)
  let dest =
    source.substring(0, pos + ThumbPartner.length) +
    pattern +
    source.substring(pos + ThumbPartner.length - 1 + 1)
  return dest
}
export const profilePicture = profile => {
  return !profile ? null : profile.avatar ? profile.avatar : profile.photoUrl
}

export function isAvailableNowMenu(menu, chef) {
  
  const now = moment().format()
  const weekDay = moment().format('dddd')
  const currentDate = moment().format('YYYY-M-DD')

  if (menu.useBusinessHours || !menu.weekday) {
    const chefBusinessHours = getChefBusinessHours(chef)

    if (!chefBusinessHours.weekDays.includes(weekDay)) {
      return false
    }
    let fromHour = currentDate + ' ' + chefBusinessHours.start,
      endHour = currentDate + ' ' + chefBusinessHours.end
    try {
      if (
        moment(now).isAfter(moment(fromHour)) &&
        moment(now).isBefore(moment(endHour))
      ) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log(e)
      return false
    }
  } else {
    //schedule time
    if (!menu.weekday.includes(weekDay)) {
      return false
    }
    let fromHour = currentDate + ' ' + menu.fromHour,
      endHour = currentDate + ' ' + menu.endHour
    try {
      if (
        moment(now).isAfter(moment(fromHour)) &&
        moment(now).isBefore(moment(endHour))
      ) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }
}

export function isAvailableNow(dishId, chef, menus = []) {
  const now = moment().format()
  const weekDay = moment().format('dddd')
  const currentDate = moment().format('YYYY-M-DD')
  let startHours = [],
    endHours = [],
    availableWeekDays = [],
    availableTimeRange = {},
    chefBusinessHours = getChefBusinessHours(chef)

  if (menus.length > 0) {
    //dish has menus( the counts of menu is more than one.)
    //get available dish' weekdays, hours from both schedule time or business time

    for (let i = 0; i < menus.length; i++) {
      if (menus[i].type == 'parent' || !menus[i].items.includes(dishId))
        continue
      if (!menus[i].weekday) {
        //use BusinessHour
        availableWeekDays = [
          ...availableWeekDays,
          ...chefBusinessHours.weekDays
        ]
      } else {
        availableWeekDays = [...availableWeekDays, ...menus[i].weekday]
      }
      if (!menus[i].fromHour || !menus[i].endHour) {
        //under business hours
        startHours = [...startHours, chefBusinessHours.start]
        endHours = [...endHours, chefBusinessHours.end]
      } else {
        //under schedule time
        startHours = [...startHours, menus[i].fromHour]
        endHours = [...endHours, menus[i].endHour]
      }
    }
    availableWeekDays = Array.from(new Set(availableWeekDays))
    console.log(availableWeekDays, 'availableWeekDays!!!')
    startHours = Array.from(new Set(startHours)).sort(
      (a, b) => moment(a).unix - moment(b).unix
    )
    endHours = Array.from(new Set(endHours)).sort(
      (a, b) => moment(a).unix - moment(b).unix
    )
    availableTimeRange = {
      start: startHours[0],
      end: endHours[0]
    }
    console.log(
      availableTimeRange,
      availableWeekDays,
      'start, end Hours, availabe weekdays!!!'
    )
    //check if dish is under available weekdays
    if (!availableWeekDays.includes(weekDay)) {
      return false
    }
    let fromHour = currentDate + ' ' + availableTimeRange.start,
      endHour = currentDate + ' ' + availableTimeRange.end
    try {
      if (
        moment(now).isAfter(moment(fromHour)) &&
        moment(now).isBefore(moment(endHour))
      ) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log(e)
      return false
    }
  } else {
    // dish has no menu then fall in under chef's business hours
    //checking businessHours
    if (!chefBusinessHours.weekDays.includes(weekDay)) {
      return false
    }
    let fromHour = currentDate + ' ' + chefBusinessHours.start,
      endHour = currentDate + ' ' + chefBusinessHours.end
    try {
      if (
        moment(now).isAfter(moment(fromHour)) &&
        moment(now).isBefore(moment(endHour))
      ) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }
}
function getChefBusinessHours(chef) {
  let availableWeekDays = [],
    startHours = [],
    endHours = []

  if (chef && chef.businessHours && chef.businessHours.length > 0) {
    let chefWeekDays = chef.businessHours.map(d => d.business_days)
    for (let weekdays of chefWeekDays) {
      availableWeekDays = [...availableWeekDays, ...weekdays]
    }
    startHours = [...startHours, ...chef.businessHours.map(d => d.fromHour)]
    endHours = [...endHours, ...chef.businessHours.map(d => d.endHour)]
    availableWeekDays = Array.from(new Set(availableWeekDays))

    startHours = Array.from(new Set(startHours)).sort(
      (a, b) => moment(a).unix - moment(b).unix
    )
    endHours = Array.from(new Set(endHours)).sort(
      (a, b) => moment(a).unix - moment(b).unix
    )

    return {
      weekDays: availableWeekDays,
      start: startHours[0],
      end: endHours[0]
    }
  }
  return {
    weekDays: [],
    start: '',
    end: ''
  }
}
