<template>
  <div class="cart-wrapper">
    <v-container>
      <v-data-table
        :headers="headers"
        :items="cart"
        hide-default-footer
        class="elevation-2 ma-4"
      >
        <template v-slot:no-data>
          <span>{{ $t('Common.noItemsFound') }}</span>
        </template>
        <template v-slot:item.product="{ item }">
          <div class="d-flex align-center">
            <img
              :src="dishImage(item)"
              class="img-responsive my-2 ml-4 mr-8"
              height="100px"
              width="100px"
            />
            <div class="d-flex justify-start flex-column pl-8">
              <span class="py-2 d-flex justify-start">
                {{ item.dish.name }}
              </span>
              <small class="d-flex flex-column align-start">
                <view-modifiers :modifiers="item.modifiers" />
              </small>
              <small class="d-flex flex-column align-start">
                <span
                  v-if="item.specialInstruction"
                  class="d-flex justify-start"
                  >Special Instruction:</span
                >
                <span class="d-flex justify-start">
                  {{ item.specialInstruction }}
                </span>
              </small>
            </div>
          </div>
        </template>
        <template v-slot:item.quantity="{ item }">
          <input
            type="number"
            min="1"
            style="text-align:center;height: 40px"
            :value="item.orderAmount"
            @change="onChangeQuantity($event, item)"
          />
        </template>
        <template v-slot:item.price="{ item }">
          <span class="fw-light error--text">
            <template v-if="item.last_call">
              <span>{{ item.dish.price | currency }}</span>
              <span class="info--text">
                <!-- <br />
                {{ item.last_call.price | currency }}-->
              </span>
            </template>
            <template v-else>{{ item.dish.price | currency }}</template>
          </span>
        </template>
        <template v-slot:item.total="{ item }">
          {{ (item.orderAmount * productPrice(item)) | currency }}
          <small v-if="getModifiersSubTotal(item.modifiers) > 0">
            <br />
            Extra:
            {{
              (getModifiersSubTotal(item.modifiers) * item.orderAmount)
                | currency
            }}
          </small>
        </template>
        <template v-slot:item.available="{ item }">
          {{ isAvailable(item) }}
        </template>
        <template v-slot:item.remove="{ item }">
          <v-btn icon @click="deleteProductFromCart(item)">
            <v-icon color="grey lighten-1">close</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-divider class="my-1" horizontal></v-divider>
          <v-row class="pa-4">
            <v-col
              cols="6"
              lg="3"
              class="d-flex flex-column align-center justify-center"
            >
              <v-row class="w-100">
                <v-col cols="12">
                  <v-text-field
                    :disabled="!cart || cart.length == 0"
                    solo
                    dense
                    :hide-details="true"
                    v-model="promoCode"
                    placeholder="Promo Code"
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-alert v-show="isPromotionError" type="error" class="pa-1">
                  {{ promotionAlert }}
                </v-alert>
              </v-row>
              <v-row>
                <v-dialog v-model="couponStacking" width="500">
                  <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title
                      >Coupon Error</v-card-title
                    >

                    <v-card-text
                      >Can not apply more than one coupon at a time</v-card-text
                    >

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="couponStacking = false"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-col>
            <v-col cols="2" lg="1" class="d-flex justify-center">
              <h5
                v-if="home.all_fees && home.all_fees.discount"
                class="success--text"
              >
                {{ home.all_fees.discount | currency }}
              </h5>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex justify-center align-center">
              <v-btn
                @click.prevent="applyPromoCode"
                :disabled="!cart || cart.length == 0 || !promoCode"
                color="cyan"
                >Apply</v-btn
              >
            </v-col>
            <v-col cols="3" lg="1" class="d-flex align-center justify-center">
              <span class="fw-semi-bold">{{ $t('Common.total') }}:</span>
              <v-tooltip bottom v-if="!hasMinimumOrder">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                    <span>
                      <v-icon
                        title="Is less than minimum order "
                        small
                        color="red"
                        >info</v-icon
                      >
                    </span>
                  </v-btn>
                </template>
                <span>
                  {{
                    $t('Common.minimum_order_amount_msg', {
                      amount: chef.settings.minimumOrderAmount
                    })
                  }}
                </span>
              </v-tooltip>
              <span class="fw-semi-bold" v-if="home.all_fees">{{
                home.all_fees.sub_total | currency
              }}</span>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex justify-center align-center">
              <v-btn
                color="primary"
                :disabled="showCheckout || !cart || cart.length == 0"
                @click="beganCheckout"
                >Checkout</v-btn
              >
            </v-col>
            <v-col cols="5" lg="3" class="d-flex justify-center align-center">
              <div v-if="chef">
                <v-btn
                  class="blue--text"
                  text
                  :disabled="!cart || cart.length == 0"
                  :to="chef.handle?`/tag/${chef.handle}`:`/chef/${chef.id}`"
                  >More from {{chef.name}} </v-btn
                >
              </div>
            </v-col>
          </v-row>
          <h4
            v-if="suggestedProducts && suggestedProducts.length > 0"
            class="pl-4"
          >
            Suggested Items:
          </h4>
          <v-row v-if="suggestedProducts && suggestedProducts.length > 0">
            <v-col cols="12" class="d-flex ma-0 pa-0">
              <v-slide-group
                multiple
                show-arrows
                center-active
                :prev-icon="'mdi-arrow-left-drop-circle'"
                :next-icon="'mdi-arrow-right-drop-circle'"
                class="d-flex w-100 pa-4"
              >
                <v-slide-item
                  v-for="(product, key) in suggestedProducts"
                  :key="key"
                >
                  <v-card class="ma-4" height="230" width="300">
                    <v-img
                      class="white--text align-end"
                      v-if="getProductImage(product.id)"
                      :src="getProductImage(product.id)"
                    >
                      <v-card-title
                        class="pa-2"
                        style="background-color: rgba(0, 0, 0, 0.3);"
                        >{{ product.name | truncate(22) }}</v-card-title
                      >
                    </v-img>
                    <v-card-text
                      class="text--primary d-flex justify-space-between align-center"
                    >
                      <h5 class="ma-0">Price: ${{ product.price }}</h5>
                      <v-btn
                        color="orange"
                        text
                        @click="addItemToCart(product)"
                      >
                        <v-icon>mdi-cart-plus</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        heading="Are You Sure You Want To Delete?"
        message="Are you sure you want to delete this product from your cart?"
        @onConfirm="onDeleteProductFromCart"
      ></delete-confirmation-dialog>
    </v-container>
    <checkout :promo_code="promoCode" />
    <pagination-alert
      ref="paginationAlert"
      heading="Warning"
      message="Are you sure you want to leave this page without saving contents?"
    ></pagination-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkout from './Checkout'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'
import { analytics } from '@/firebase-init'
import ViewModifiers from 'fod-core/src/components/dish/ViewModifiers.vue'

export default {
  components: {
    Checkout,
    ViewModifiers,
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue')
  },
  mixins: [FoodMixin],
  data() {
    return {
      suggestedProducts: [],
      promotionAlert: '',
      isPromotionError: false,
      selectDeletedProduct: null,
      loader: false,
      totalPrice: 0,
      discount: 0,
      promoCode: null,
      promoCodeUsed: false,
      promotion: null,
      showCheckout: false,
      unsubscribe: null,
      couponStacking: false,

      headers: [
        {
          text: '',
          value: 'order_no',
          sortable: false
        },
        {
          text: 'Product',
          value: 'product',
          align: 'center',
          sortable: false
        },
        {
          text: 'Quantity',
          value: 'quantity',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Price',
          value: 'price',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Sub total',
          value: 'total',
          sortable: false,
          align: 'center'
        },

        {
          text: 'Remove',
          value: 'remove',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      home: 'cart',
      chef: 'chef',
      loading: 'loading',
      emailPromo: 'emailPromo'
    }),
    cart() {
      console.log(this.home.cart, 'cart lists')
      return this.home ? this.home.cart : null
    },
    chefFromCart() {
      if (this.cart && this.cart.length > 0) {
        return this.cart[0].dish.chef
      }
    },
    chef2() {
      return this.chefFromCart
    },
    hasMinimumOrder() {
      if (
        this.chef &&
        this.chef.settings &&
        this.chef.settings.minimumOrderAmount
      ) {
        return (
          this.home.all_fees &&
          this.home.all_fees.sub_total + this.home.all_fees.tip >=
            this.chef.settings.minimumOrderAmount
        )
      }
      return true
    },
    totalAfterDiscount() {
      let total = this.totalPrice - this.discount
      return total > 0 ? total : 0
    }
  },
  created() {
    this.$store.dispatch('touchCart')
  },
  async mounted() {
    this.$store.dispatch('loadCart', { load_chef: true }).then(o => {
      this.unsubscribe = o

      // need to discuss promo priority
      if (this.emailPromo) {
        console.log('Email code: ', this.emailPromo, ' applied.')
        this.promoCode = this.emailPromo
      } else {
        this.promoCode = this.home.promo_code
      }
      /* if (o) {
        this.promoCode = o.promo_code
        this.applyPromoCode()
      }
      this.getTotalPrice() */
      this.suggestedProducts = this.getSuggestedProducts()
    })
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  // beforeRouteLeave(to, from, next) {
  // this.$refs.paginationAlert.openDialog()
  // this.$nextTick(() => {
  //   this.$refs.paginationAlert.$once('onYes', () => {
  //     next(true)
  //   })
  //   this.$refs.paginationAlert.$once('onCancel', () => {
  //     next(false)
  //   })
  // })
  // },
  methods: {
    /**
     * show total price of product according to quantity change
     */
    addItemToCart(product) {
      if (product.modifiers) {
        this.$router.push('/dish/' + product.id)
      } else {
        let that = this
        this.$store
          .dispatch('addToCart', { dish: product, orderAmount: 1 })
          .then(async () => {
            that.suggestedProducts = await that.getSuggestedProducts()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    beganCheckout() {
      this.showCheckout = true
      let total = 0
      if (this.cart){
        this.cart.forEach(c => {
          total += c.orderAmount*c.sub_total          
        })
      }
      
      analytics.logEvent('begin_checkout', {
        value: total,
        items: this.cart ? this.cart.length : 0,
        uid: this.profile.uid
      })
    },
    async getSuggestedProducts() {
      if (!this.chef) {
        return
      }
      if (!this.chef.id) {
        console.error(`Producer has null id`, this.chef)
        return
      }
      let items = [],
        cartProducts = [],
        suggested = []
         
      const isFollowing = await this.$store.dispatch(
        'isFollowing',
        this.chef.id
      )

      if (this.home && this.home.cart && this.home.cart.length > 0) {
        cartProducts = this.home.cart.map(cp => cp.dish)
        for (let cart_product of cartProducts) {
          //if dish has suggested_menus
          if (
            cart_product.suggested_menus &&
            cart_product.suggested_menus.length > 0
          ) {
            for (let i = 0; i < cart_product.suggested_menus.length; i++) {
              let ret = await this.$store.dispatch('getDishItemsFromMenuId', {
                id: cart_product.suggested_menus[i],
                isFollowing: isFollowing
              })
              items.push(...ret)
            }
          }
          //if dish has suggested_items
          if (
            cart_product.suggested_items &&
            cart_product.suggested_items.length > 0
          ) {
            items.push(...cart_product.suggested_items)
          }
        }
        //remove repeated items
        items = [...new Set(items)]
        // if cart items include items, remove them from suggested items
        items = items.filter(
          item => !this.cart.map(c => c.dish.id).includes(item)
        )

        for (let i = 0; i < items.length; i++) {
          let productInfo = await this.$store.dispatch(
            'getDishInfoFromDishId',
            items[i]
          )
          suggested.push(productInfo)
        }
        console.log(suggested, 'suggested items final !!!!!!!')
        return suggested
      }
      return null
    },
    getProductImage(productID) {
      const product = this.suggestedProducts.filter(p => p.id === productID)[0]
      return product && product.photos && product.photos.length > 0
        ? product.photos[0].url || product.photos[0].imageUrl
        : ''
    },
    productPrice(product) {
      return product.last_call ? product.last_call.price : product.dish.price
    },
    onChangeQuantity(e, cartItem) {
      this.$store.dispatch('changeQuantityHandler', {
        e,
        cartItem
      })
      //this.getTotalPrice()
    },
    dishImage(payload) {
      return payload.dish &&
        payload.dish.photos &&
        payload.dish.photos.length > 0
        ? payload.dish.photos[0].url || payload.dish.photos[0].imageUrl
        : null
    },
    showDetail(item) {
      this.$router.push('/dish/' + item.dish.id)
    },
    isAvailable(item) {
      if (item.dish.amountLeft > 0) {
        return item.dish.amountLeft
      } else if (item.dish.amountLeft == -1) {
        return 'Unlimited'
      } else {
        return 'N/A'
      }
    },
    /**
     * open dialog to delete product
     */
    deleteProductFromCart(product) {
      this.$refs.deleteConfirmationDialog.openDialog()
      this.selectDeletedProduct = product
      //this.getTotalPrice()
    },
    /**
     * delete product
     */
    onDeleteProductFromCart() {
      this.$refs.deleteConfirmationDialog.close()
      this.loader = true
      let that = this
      setTimeout(() => {
        this.loader = false

        this.$store
          .dispatch('onDeleteProductFromCart', this.selectDeletedProduct)
          .then(async () => {
            //this.getTotalPrice()
            that.suggestedProducts = await that.getSuggestedProducts()
          })
      }, 1500)
    },
    priceWithPromo() {
      if (this.promotion) {
        if (this.promotion.AmountType === '$') {
          this.totalPrice -= this.promotion.Amount
          this.home.all_fees.sub_total -= this.promotion.Amount
        }
        if (this.promotion.AmountType === '%') {
          this.totalPrice *= 1 - this.promotion.Amount / 100
          this.home.all_fees.sub_total *= 1 - this.promotion.Amount / 100
        }
      }
      
      if (this.home.all_fees.sub_total <= 0) {
        this.home.all_fees.sub_total = 0
      }
      
      return 0
    },
    applyPromoCode() {
      if (this.promoCode) {
        console.log('Apply Promo Code: ', this.promoCode, this.chefFromCart)
        this.$store
          .dispatch('applyPromoCode', {
            code: this.promoCode,
            chef_id: this.chefFromCart ? this.chefFromCart.id : '',
            cart: this.cart
          })
          .then(res => {
            if (!res) {
              this.promotionAlert = 'Unable to use this coupon: invalid'
              this.isPromotionError = true
            } else {
              if (this.promoCodeUsed) {
                this.promotionAlert = 'A coupon has already been applied'
                this.isPromotionError = true
                this.couponStacking = true
              } else if (res.status && res.status === 'active') {
                this.isPromotionError = false
                this.promotion = res.code //res { code: promo code, status: 'active' or 'expired'}
                this.priceWithPromo()
                this.promoCodeUsed = true
              } else if (res.status && res.status !== 'active') {
                this.promotionAlert = `Unable to use this coupon: ${res.status}.`
                this.isPromotionError = true
              } else {
                this.promotionAlert = 'Unable to use this coupon: invalid'
                this.isPromotionError = true
              }
            }
            //this.getTotalPrice()
          })
      }
    }
  }
}
</script>
<style scoped>
.img-responsive {
  width: 100px;
  height: 60px;
  border-radius: 5px;
  border: 3px solid #ccc;
}
.image-round {
  border: gainsboro 2px solid;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.image-rect {
  border: gainsboro 4px solid;
  overflow: hidden;
  width: 100%;
  height: 135px;
}
</style>
