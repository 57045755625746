<template>
  <v-container v-if="chef && cart && cart.length > 0">
    <v-row>
      <v-col cols="12" sm="12" xl="8" lg="6" md="6">
        <h2 class="px-3 py-4 mb-0">{{ $t('Common.checkout') }}</h2>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12" sm="12" lg="8" md="12" class="mb-2">
        <v-card >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex">
                <h4 class="mb-0">{{ $t('Common.delivery_option') }}*:</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="d-flex justify-center">
                <v-btn
                  :color="deliveryOptionColor('delivery')"
                  @click="deliveryOption('delivery')"
                  >{{ $t('Common.delivery') }}</v-btn
                >
              </v-col>
              <v-col cols="4"></v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-btn
                  :disabled="chef.settings && !chef.settings.canPickup"
                  :color="deliveryOptionColor('pickup')"
                  @click="deliveryOption('pickup')"
                  >Pickup</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="pickup_address">
                <v-text-field
                  label="Pickup Address"
                  :disabled="true"
                  v-model="pickup_address"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="hasDropOffAddress">
              <v-col cols="12">
                <v-select
                  required
                  :items="all_dropoff_address"
                  v-model="dropoff_address"
                  @change="onChangeDropOffAddress"
                  label="Dropoff Address"
                ></v-select>
                <v-col cols="12" sm="3"></v-col>
              </v-col>
            </v-row>
            <v-col cols="12" v-if="deliveryEstimate">
              <v-alert type="info" outlined :value="true">
                Estimated delivery fee is
                {{ (deliveryEstimate.fee / 100) | currency }}
              </v-alert>
            </v-col>
            <v-row v-if="showDropoffInfo">
              <v-col cols="12" sm="6">
                <v-text-field
                  filled
                  label="Dropoff name"
                  @change="updateDropOff"
                  v-model="dropoff_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  filled
                  label="Dropoff phone"
                  @change="updateDropOff"
                  v-model="dropoff_phone_number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card
                  v-if="location"
                  color="grey lighten-1"
                  class="mb-5 map demo"
                  height="300px"
                >
                  <google-map
                    :center="location"
                    class="map"
                    :zoom="13"
                    ref="map"
                  >
                    <google-marker
                      :position="location"
                      :clickable="true"
                      :draggable="true"
                    ></google-marker>
                  </google-map>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex">
                <h4 class="mb-0">{{ $t('Common.delivery_time') }}*:</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="d-flex justify-center">
                <v-btn
                  :disabled="!enableNow || !availableWithCart"
                  :color="orderTypeOptionColor('now')"
                  @click="orderTypeOption('now')"
                  >Now</v-btn
                >
                <v-tooltip bottom v-if="!enableNow || !availableWithCart">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon small color="red">info</v-icon>
                    </span>
                  </template>
                  <span v-if="!enableNow">
                    {{ $t('Common.out_of_business') }}
                  </span>
                  <span v-if="!availableWithCart">
                    {{ $t('Common.not_enough_left2') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" class="d-flex justify-center"></v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-btn
                  :disabled="preorderDisabled()"
                  :color="orderTypeOptionColor('later')"
                  @click="orderTypeOption('later')"
                  >Later</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="4"
                class="justify-center"
                v-if="orderType === 'later'"
              >
                <v-select
                  :items="deliveryTimeRange"
                  v-model="deliveryTimeSelect"
                  label="Time"
                  @change="changeDeliveryTime"
                ></v-select>
              </v-col>
              <v-col cols="4" class="d-flex justify-center"></v-col>
              <v-col
                v-if="orderType === 'later'"
                cols="4"
                class="d-flex justify-center"
              >
                <v-menu
                  v-model="date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="deliveryDate"
                      label="Pre-Order Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :min="minDate"
                    :max="maxDate"
                    v-model="deliveryDate"
                    @change="onChangeDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-3">
          <v-card-title primary-title>
            <h4 class="mb-0">{{ $t('Common.payment_option') }}*:</h4>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-row v-if="!sources || sources.length == 0">
              <v-col cols="12">
                <v-alert outlined :value="true" icon="info" color="error" info
                  >You haven't setup your payment method yet.</v-alert
                >
                <v-btn color="info" small to="/accounts"
                  >Add new payment method</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-select
                  label="Payment"
                  @change="saveCart"
                  :items="payment_sources"
                  v-model="source"
                  item-text="last4"
                  item-value="id"
                  persistent-hint
                  single-line
                >
                  <template v-slot:default="{ item }">
                    {{ item.brand }} ... {{ item.last4 }} (exp.{{
                      item.exp_month
                    }}/{{ item.exp_year }})
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea label="Add order note" v-model="notes"></v-textarea>
              </v-col>
            </v-row>
            <order-fee
              @onTipChange="onTipChange"
              :home="home"
              :all_fees="home.all_fees"
              :chef="chef"
              :deliveryMethod="delivery_method"
              :deliveryCharge="deliveryCharge"
            />
            <v-divider />
          </v-card-text>
          <v-card-actions class="mx-2 pb-4">
            <v-btn
              color="info"
              :disabled="!readyForSubmit"
              @click.prevent.stop="submitOrder"
              >Submit Order</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn :disabled="!readyForSubmit" @click.prevent.stop="saveCart"
              >Save for later</v-btn
            >
            <template v-if="chef.settings && !hasMinimumOrder">
              <v-icon color="red">error</v-icon>
              Chef requires
              {{ chef.settings.minimumOrderAmount | currency }} as minimum order
            </template>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" lg="4" md="12" class="col-height-auto">
        <user-profile :profile="chef" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
import UserProfile from 'fod-core/src/components/profile/UserProfile.vue'
import OrderFee from 'fod-core/src/components/order/OrderFee.vue'
import { analytics } from '@/firebase-init'
import { globalMixin } from 'fod-core'
import { isAvailableNow } from '../../store/shared/util'

export default {
  props: ['promo_code'],
  mixins: [globalMixin],

  components: {
    'google-map': VueGoogleMaps.Map,
    'google-marker': VueGoogleMaps.Marker,
    UserProfile,
    OrderFee
  },
  data() {
    return {
      enableNow: false,
      baseDeliveryTime: 45, //mins
      basePickupTime: 20, //mins
      businessTime: {
        start: {
          hour: null,
          min: null
        },
        end: {
          hour: null,
          min: null
        }
      },
      pickupTime: {
        start: {
          hour: null,
          min: null
        },
        end: {
          hour: null,
          min: null
        }
      },
      delivery_method: null,
      deliveryDate: null,
      date_menu: false,
      deliveryTime: null,
      deliveryTimeSelect: null,
      pickup_address: null,
      pickup_name: null,
      pickup_phone_number: null,
      dropoff_address: null,
      all_dropoff_address: [],
      dropoff_name: null,
      dropoff_phone_number: null,
      location: null,
      orderType: null,
      source: null,
      notes: null,
      deliveryCharge: null,
      maxDeliveryRange: null,
      hasDropOffAddress: false,
      tip_amount: null,
      tip_type: null
    }
  },

  computed: {
    ...mapGetters({
      home: 'cart',
      chef: 'chef',
      user: 'user',
      sources: 'sources',
      profile: 'profile',
      deliveryEstimate: 'deliveryEstimate',
      loading: 'loading'
    }),
    cart() {
      return this.home ? this.home.cart : null
    },
    chef1() {
      if (this.cart && this.cart.length > 0) {
        return this.cart[0].dish.chef
      }
    },
    deliveryTimeRange() {
      let startTime = null
      if (moment().format('YYYY-MM-DD') == this.deliveryDate) {
        startTime = moment()
          .add(15, 'm')
          .format('HH:mm')
      }
      if (this.chef.businessHours) {
        //const today = moment().format('dddd')
        const today = moment(this.deliveryDate).format('dddd')

        let schedule = this.chef.businessHours.filter(b =>
          b.business_days.includes(today)
        )
        console.log(today, schedule, ':today schedule')
        if (schedule && schedule.length > 0) {
          let fromHour =
              this.convert24hr(schedule[0].fromHour).hour +
              ':' +
              this.convert24hr(schedule[0].fromHour).min,
            endHour =
              this.convert24hr(schedule[0].endHour).hour +
              ':' +
              this.convert24hr(schedule[0].endHour).min
          startTime = startTime ? startTime : fromHour
          return this.timeStep(startTime, endHour)
        } else {
          this.$store.dispatch('setMessage', {
            title: 'Out of business hours',
            body: 'Select different date or time'
          })
        }
        
      }
      startTime = startTime ? startTime : '06:00'
      return this.timeStep(startTime)
    },
    subTotal() {
      return this.home && this.home.all_fees ? this.home.all_fees.sub_total : 0
    },
    availableWithCart() {
      let available = true
      if (this.cart && this.cart.length > 0) {
        this.cart.forEach(c => {
          if (
            c.dish.amountLeft != -1 &&
            c.dish.amountLeft - c.orderAmount < 0
          ) {
            available = false
          }
        })
      }
      return available
    },

    readyForSubmit() {
      if (!this.chef ){
        return
      }
      if (this.chef &&
        this.chef.settings &&
        !this.chef.settings.canDeliver &&
        !this.chef.settings.allowThirdPartyDelivery &&
        !this.chef.settings.canPickup
      ) {
        return false
      }
      if (!this.orderType) {
        return false
      }
      if (!this.hasMinimumOrder) {
        return false
      }
      switch (this.orderType) {
        case 'later':
        case 'today':
          if (!this.deliveryDate || !this.deliveryTime) {
            return false
          }

          break
      }
      return this.source != null && this.delivery_method
    },
    showDropoffInfo() {
      return (
        this.delivery_method === 'delivery' ||
        this.delivery_method === 'third-party'
      )
    },
    maxDate() {
      const date = new Date()
      date.setDate(date.getDate() + 30)
      return date.toISOString().substr(0, 10)
    },
    minDate() {
      return moment().format('YYYY-MM-DD')
    },
    canProviderDeliver() {
      if (!this.chef.settings) {
        // default is true
        return true
      }
      return (
        this.chef.settings.canDeliver ||
        this.chef.settings.allowThirdPartyDelivery
      )
    },
    hasMinimumOrder() {
      if (this.chef.settings && this.chef.settings.minimumOrderAmount) {
        return (
          this.subTotal + this.home.all_fees.tip >=
          this.chef.settings.minimumOrderAmount
        )
      }
      return true
    },
    payment_sources() {
      return this.sources.filter(source => source.id && !source.error)
    }
  },
  mounted() {
    console.log(this.chef, ' current chef!!!')
    this.$gtm.trackView('Checkout', 'currentpath')
    this.$store.dispatch('checkAccount')

    if (this.chef &&
      this.chef.settings &&
      (this.chef.settings.canDeliver ||
        this.chef.settings.allowThirdPartyDelivery)
    ) {
      this.deliveryOption('delivery')
    } else {
      this.deliveryOption('pickup')
    }
    this.setTimeInfo()
    this.deliveryTime = this.getCurrentTime()

    
    //check OrderNow can be done right now
    this.checkAvailableNow()
  },

  methods: {
    checkAvailableNow() {
      if (!this.home || !this.home.cart || this.home.cart.length < 1) {
        this.enableNow = false
        return
      }
      let menus = [],
        available_arr = [],
        dish_ids = []

      this.$store
        .dispatch('getChefFromDish', this.home.cart[0].dish.uid)
        .then(chef => {
          dish_ids = this.home.cart.map(dishInfo => dishInfo.dish.id)
          this.$store
            .dispatch('getMenusPerDishes', {
              chefId: this.home.cart[0].dish.uid,
              dishIds: dish_ids
            })
            .then(res => {
              res.forEach(each => {
                let eachAvailable = isAvailableNow(
                  each.dishId,
                  chef,
                  each.menus
                )
                available_arr.push(eachAvailable)
              })
              const available = !available_arr.includes(false)
              this.enableNow = available
              return
            })
        })
    },
    isRestaurant() {
      if (this.chef.permits) {
        return this.chef.permits[0].type.text.toLowerCase().includes('RES')
      } else {
        return ''
      }
    },
    changeDeliveryTime() {
      let timeSelected = moment(this.deliveryTimeSelect, 'h:m a')
      this.deliveryTime = {
        hour: timeSelected.format('H'),
        min: timeSelected.format('m')
      }
    },
    getAvailableAddessInfo(address, type) {
      let store_address = {}
      for (var key in address) {
        if (key === 'formatted_address' && address[key]) {
          store_address[type + '_address'] = address[key]
        }
        if (key === 'address2' && address[key]) {
          store_address[type + '_' + key] = address[key]
        }
        if (key === 'address_instruction' && address[key]) {
          store_address[type + '_' + key] = address[key]
        }
      }

      return store_address
    },
    preorderDisabled() {
      
      if (
        !this.chef ||
        !this.chef.settings ||
        this.chef.settings.allow_preorder ||
        this.chef.settings.allow_preorder === undefined
      ) {
        return false
      } else {
        return true
      }
    },
    onChangeDropOffAddress(value) {
      if (value === 'Add new address...') {
        this.$router.push({
          name: 'MyProfile',
          params: { view_mode: 'address' }
        })
      }
    },
    getCurrentTime(delivery_method) {
      let now = moment().add(
        delivery_method === 'pickup'
          ? this.basePickupTime
          : this.baseDeliveryTime,
        'm'
      )
      return {
        hour: now.format('HH'),
        min: now.format('mm')
      }
    },
    allowedDates(date) {
      return parseInt(date.split('-')[2]) > this.getCurrentTime().day
    },
    setTimeInfo() {
      //pickup available time
      if (!this.chef || !this.chef.settings) {
        return
      }
      if (this.chef.settings.pickupTimeEarliest) {
        this.pickupTime.start.hour = this.chef.settings.pickupTimeEarliest.split(
          ':'
        )[0]
        this.pickupTime.start.min = this.chef.settings.pickupTimeEarliest.split(
          ':'
        )[1]
      }
      if (this.chef.settings.pickupTimeLatest) {
        this.pickupTime.end.hour = this.chef.settings.pickupTimeLatest.split(
          ':'
        )[0]
        this.pickupTime.end.min = this.chef.settings.pickupTimeLatest.split(
          ':'
        )[1]
      }
      //business available time
      if (this.chef.businessHours && this.chef.businessHours.length > 0) {
        this.businessTime.start.hour = this.convert24hr(
          this.chef.businessHours[0].fromHour
        ).hour
        this.businessTime.start.min = this.convert24hr(
          this.chef.businessHours[0].fromHour
        ).min
        this.businessTime.end.hour = this.convert24hr(
          this.chef.businessHours[0].endHour
        ).hour
        this.businessTime.end.min = this.convert24hr(
          this.chef.businessHours[0].endHour
        ).min
      }
    },
    convert24hr(strHour) {
      let hour24 = '',
        min = ''
      if (strHour.toLowerCase().includes('pm')) {
        hour24 = strHour.split(' ')[0].split(':')[0] / 1 + 12
      } else {
        hour24 = strHour.split(' ')[0].split(':')[0] / 1
      }
      return {
        hour: hour24,
        min: strHour.split(' ')[0].split(':')[1]
      }
    },
    updateAddress() {
      console.log('Address updated')
      this.saveCart()
    },
    onChangeDate() {
      this.saveCart()
    },
    orderTypeOptionColor(option) {
      if (this.orderType === option) return 'success'
    },
    orderTypeOption(option) {
      this.orderType = option
      if (option === 'now') {
        this.deliveryDate = moment().format('YYYY-MM-DD')
      } else {
        this.deliveryDate = moment()
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    deliveryOptionColor(option) {
      if (this.delivery_method === option) return 'info'
    },
    deliveryOption(option) {
      analytics.logEvent('add_shipping_info', {        
        method :this.delivery_method,
        uid: this.profile?this.profile.uid:null
      })
      this.delivery_method = option
      //this.$store.dispatch('estimateDelivery', { reset: true })
      if (!this.delivery_method) return
      if (!this.profile) return
      this.dropoff_phone_number = this.profile.phone
      this.dropoff_name = this.profile.name
      this.dropoff_address = null
      this.pickup_address = null
      this.pickup_phone_number = null
      this.pickup_name = null

      this.all_dropoff_address = this.profile.addresses.map(
        e => e.formatted_address
      )
      if (this.all_dropoff_address.length < 4)
        this.all_dropoff_address.push('Add new address...')
      this.dropoff_address = this.all_dropoff_address[0]

      switch (this.delivery_method) {
        case 'pickup':
          this.pickup_address = this.profileFormattedAddress(
            this.chef,
            'pickup'
          )
          var addr = this.profileAddress(this.chef, 'pickup')
          if (addr) {
            this.location = {}
            this.location.lat = addr.location._lat
            this.location.lng = addr.location._long
          }

          break
        case 'delivery':
          this.pickup_address = this.profileFormattedAddress(
            this.chef,
            'pickup'
          )
          this.pickup_phone_number = this.chef.businessPhone
            ? this.chef.businessPhone
            : this.chef.phone
          this.pickup_name = this.chef.businessName
            ? this.chef.businessName
            : this.chef.name

          if (this.chef.settings) {
            this.deliveryCharge = this.chef.settings.deliveryCharge
            this.maxDeliveryRange = this.chef.settings.maxDeliveryRange
          }
          addr = this.profileAddress(this.profile)

          if (addr) {
            this.location = {}
            this.location.lat = addr.location.latitude
            this.location.lng = addr.location.longitude
          }
          break

        /*    case 'third-party':
          this.pickup_address = this.profileFormattedAddress(
            this.chef,
            'pickup'
          )
          this.dropoff_address = this.profileFormattedAddress(this.profile)

          if (
            this.dropoff_address &&
            this.dropoff_address != this.pickup_address
          ) {
            this.$store.dispatch('estimateDelivery', {
              type: this.delivery_method,
              pickup_address: this.pickup_address,
              dropoff_address: this.dropoff_address
            })
          } else {
            this.$store.dispatch('setError', {
              message: 'Drop off address is not valid'
            })
          }
          break */
        default:
          break
      }

      // this.$emit('onUpdateAddress', {
      //   pickup_address: this.pickup_address,
      //   deliveryEstimate: this.deliveryEstimate,
      //   dropoff_address: this.dropoff_address,
      //   delivery_method: this.delivery_method
      // })

      // if dropoff address is not empty, displays the drop off input
      if (this.dropoff_address) {
        this.hasDropOffAddress = true
      }
      this.saveCart()
      // this.setTimeInfo(option)
    },

    saveCart() {
      if (!this.deliveryTime) return
      let selected_dropoff_address = this.getAvailableAddessInfo(
        this.profile.addresses.filter(
          e => e.formatted_address === this.dropoff_address
        )[0],
        'dropoff'
      )
      let selected_pickup_address = this.getAvailableAddessInfo(
        this.chef.addresses[0],
        'pickup'
      )

      let delivery = {
        deliveryEstimate: this.deliveryEstimate,
        deliveryCharge: this.deliveryCharge,
        ...selected_pickup_address,
        pickup_name: this.pickup_name,
        pickup_phone_number: this.pickup_phone_number,
        ...selected_dropoff_address,
        dropoff_phone_number: this.dropoff_phone_number,
        dropoff_name: this.dropoff_name,
        delivery_method: this.delivery_method
      }
      let request = {
        updatedTime: moment().unix(),
        deliveryMethod: this.delivery_method,
        notes: this.notes,
        deliveryDate: this.deliveryDate,
        deliveryTime: this.deliveryTime.hour + ':' + this.deliveryTime.min,
        delivery: delivery,
        orderType: this.orderType,
        payment: {
          promo_code: this.promo_code || null,
          source: this.source,
          tip_amount: this.tip_amount,
          tip_type: this.tip_type
        }
      }

      this.$store.dispatch('saveCart', request)
    },
    submitOrder() {
      let selected_dropoff_address = this.getAvailableAddessInfo(
        this.profile.addresses.filter(
          e => e.formatted_address === this.dropoff_address
        )[0],
        'dropoff'
      )
      let selected_pickup_address = this.getAvailableAddessInfo(
        this.chef.addresses[0],
        'pickup'
      )

      let delivery = {
        deliveryEstimate: this.deliveryEstimate,
        deliveryCharge: this.deliveryCharge,
        ...selected_pickup_address,
        pickup_name: this.chef.businessName
          ? this.chef.businessName
          : this.chef.name,
        pickup_phone_number: this.pickup_phone_number,
        ...selected_dropoff_address,
        dropoff_phone_number: this.dropoff_phone_number,
        dropoff_name: this.dropoff_name,
        delivery_method: this.delivery_method
      }
      let request = {
        deliveryMethod: this.delivery_method,
        notes: this.notes,
        deliveryDate: this.deliveryDate,
        deliveryTime: this.deliveryTime.hour + ':' + this.deliveryTime.min,
        delivery: delivery,
        orderType: this.orderType,
        payment: {
          source: this.source,
          tip_amount: this.tip_amount,
          tip_type: this.tip_type
        },
        status: 'submitting'
      }

      //this.$store.dispatch('syncCart')

      this.$store
        .dispatch('submitOrder', request)
        .then(data => {
          console.log('order data', data)
          if (data != null) {
            this.order = data
          }
          this.$router.push('/order/list')
          this.$store.dispatch('setMessage', {
            title: 'In Progress',
            body: 'Your order submitted to Chef.'
          })
        })
        .catch(error => {
          console.log(error)
          this.$store.dispatch('setError', {
            message: 'Can not submit the order'
          })
        })
    },
    onTipChange(payload) {
      console.log('Tip', payload)
      this.tip_amount = parseFloat(payload.tip_amount)
      this.tip_type = payload.tip_type
      this.saveCart()
    },
    updateDropOff() {
      this.saveCart()
    }
  }
}
</script>

<style scoped>
.demo {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map {
  flex: 100% 1 1;
}
.location_icon {
  position: relative;
}
</style>
