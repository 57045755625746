<template>
  <v-row justify="center">
    <v-col cols="12" lg="8" v-if="all_fees">
      <v-list>
        <v-list-item>
          <v-list-item-title>{{ $t("Common.sub_total") }}</v-list-item-title>
          <v-list-item-action>
            {{ all_fees.sub_total | currency }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-if="
            method === 'delivery' &&
              all_fees.delivery_fee !== null &&
              all_fees.delivery_fee > 0
          "
        >
          <v-list-item-title>{{ $t("Common.delivery_fee") }}</v-list-item-title>
          <v-list-item-action>{{
            all_fees.delivery_fee | currency
          }}</v-list-item-action>
        </v-list-item>

        <v-list-item
          v-if="
            method === 'delivery' &&
              all_fees.discount !== null &&
              all_fees.discount > 0
          "
        >
          <v-list-item-title>{{ $t("Common.discount") }}</v-list-item-title>
          <v-list-item-action>
            {{ all_fees.discount | currency }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="all_fees.delivery_subsidy > 0">
          <v-list-item-title>{{
            $t("Common.delivery_subsidy")
          }}</v-list-item-title>
          <v-list-item-action>
            {{ all_fees.delivery_subsidy | currency }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="isChef">
          <v-list-item-title>{{
            $t("Common.sub_total_net")
          }}</v-list-item-title>
          <v-list-item-action>
            {{ all_fees.sub_total_net | currency }}
          </v-list-item-action>
        </v-list-item>
        <!-- tip section -->
        <v-list-item v-if="!isChef">
          <v-list-item-content>
            <v-list-item-title>{{ $t("Common.tip") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <template v-for="(tip, index) in tipList">
                <v-chip
                  :key="tip"
                  small
                  class="ma-2"
                  :color="tipChecked[index] ? 'primary' : ''"
                  @click="onTapChange(tip, index, '%')"
                  >{{ tip }} {{ tip === "other" ? "" : "%" }}</v-chip
                >
              </template>
            </v-row>
          </v-list-item-action>
        </v-list-item>
        <!-- tip distribution message -->
        <v-list-item v-if="tipAmount && tipAmount !== 'none'">
          <small>*{{ $t("Common.tipDistribution") }}</small>
        </v-list-item>
        <template v-if="customTip != null">
          <!-- custom tip input -->
          <v-list-item>
            <v-row>
              <v-col cols="8" class="d-flex align-center">
                <v-text-field
                  label="custom tip"
                  placeholder="Custom Tip"
                  solo
                  min="1"
                  type="number"
                  :suffix="tip_type"
                  v-model="customTip"
                  @change="onCustomTapChange(customTip, tip_type)"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-radio-group v-model="tip_type">
                  <v-radio
                    label="Percent"
                    color="primary"
                    value="%"
                    class="fsx-12"
                    @change="onCustomTapChange(customTip, tip_type)"
                  ></v-radio>
                  <v-radio
                    label="Amount"
                    color="primary"
                    value="$"
                    class="fsx-12"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-list-item>
        </template>
        <!-- tip amount display -->
        <v-list-item v-if="all_fees.tip">
          <v-list-item-title>{{ $t("Common.tip_amount") }}</v-list-item-title>
          <v-list-item-action v-if="isChef">{{
            all_fees.producer_tip | currency
          }}</v-list-item-action>
          <v-list-item-action v-else>{{
            all_fees.tip | currency
          }}</v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            {{ $t("Common.taxes_fees") }}
            <v-icon @click.stop="fee_dialog = true" small>info</v-icon>
          </v-list-item-title>

          <v-list-item-action v-if="isChef">{{
            (all_fees.tax + all_fees.service_fee) | currency
          }}</v-list-item-action>
          <v-list-item-action v-else>{{
            (all_fees.tax + all_fees.producer_service_fee) | currency
          }}</v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            >{{ $t("Common.total") }}
            <v-tooltip bottom  v-if="isChef">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon dark v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1">info</v-icon>
                </v-btn>
              </template>
              <span class="white--text">{{$t("Common.producer_total_message")}}</span>
            </v-tooltip>
          </v-list-item-title>

          <v-list-item-action v-if="isChef">
            {{ all_fees.producer_total | currency }}
          </v-list-item-action>
          <v-list-item-action v-else>{{
            all_fees.total | currency
          }}</v-list-item-action>
        </v-list-item>
      </v-list>

      <v-dialog v-model="fee_dialog" persistent max-width="390">
        <v-card>
          <v-card-title class="headline">
            <h4>{{ $t("Common.fee_breakdown_title") }}</h4>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-title>{{ $t("Common.tax") }}</v-list-item-title>
                <v-list-item-action>{{
                  all_fees.tax | currency
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>{{
                  $t("Common.service_see")
                }}</v-list-item-title>
                <v-list-item-action v-if="isChef">{{
                  all_fees.producer_service_fee | currency
                }}</v-list-item-action>
                <v-list-item-action v-else>{{
                  all_fees.service_fee | currency
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>{{ $t("Common.total") }}</v-list-item-title>
                <v-list-item-action>
                  {{ (all_fees.tax + all_fees.service_fee) | currency }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <div v-if="!isChef" class="pa-2 grey lighten-2">
              <h4>{{ $t("Common.authorization_title") }}</h4>
              <p>{{ $t("Common.authorization_sub") }}</p>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="fee_dialog = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OrderFee",
  props: [
    "all_fees",
    "home",
    "chef",
    "sub_total",
    "deliveryCharge",
    "deliveryMethod",
    "payment",
    "isChef",
  ],
  data() {
    return {
      fee_dialog: false,
      method: "delivery",
      tipAmount: null,
      tipList: ["0", "5", "10", "15", "other"],
      tipChecked: [false, false, false, false, false],
      customTip: null,
      tip_type: "%",
    };
  },
  computed: {
    cart() {
      return this.home ? this.home.cart : null;
    },
  },
  watch: {
    deliveryMethod: function(new_method, old_method) {
      this.method = new_method;
      this.tipChecked = [false, false, false, false, false];
    },
  },
  methods: {
    round(amount) {
      return Math.round(amount * 100) / 100;
    },
    resetTip() {
      this.tipChecked = [false, false, false, false, false];
    },
    onTapChange(tip, tip_index, tip_type) {
      this.tipChecked = [false, false, false, false, false];
      this.tipChecked[tip_index] = true;
      let tip_amount = tip;
      if (tip === "other") {
        this.customTip = 0;
      } else {
        this.customTip = null;
        this.$emit("onTipChange", {
          tip_amount,
          tip_type,
        });
      }
    },
    onCustomTapChange(tip_amount, tip_type) {
      this.$emit("onTipChange", {
        tip_amount,
        tip_type,
      });
    },
  },
};
</script>
