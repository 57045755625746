<template>
  <!-- <v-row>
    <v-col cols="12" class="pa-0 ma-0">
      <div v-for="(modifier, name) in list" :key="modifier.id" class="d-flex justify-start">
        <span class="font-weight-medium font-italic">{{ name }}:</span
        >
        <span v-for="(value, key) in modifier" :key="key">
          {{ value.name }}
          <span v-if="value.price > 0"> ({{ value.price | currency }})&nbsp;&nbsp;</span>
        </span>
      </div>
    </v-col>
  </v-row>-->

  <v-list>
    <template v-for="(modifier, name) in list" class="d-flex justify-start">
      <span class="font-weight-bold" v-if="name" :key="name"> {{ name }}: </span>
      
      <template v-for="(value, key) in modifier">
        <v-list-item-content :key="value.id">
          <span class="font-regular">-{{ value.name }} </span>
          <!-- <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle> -->
        </v-list-item-content>
      </template>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    modifiers: {
      type: Object
    }
  },
  computed: {
    list() {
      let my = {};
      if (this.modifiers) {
        Object.values(this.modifiers).forEach(item => {
          if (item.modifier && item.modifier.options) {
            let selected = item.modifier.options.filter(option =>
              item.selected.includes(option.id)
            );
            if (selected) {
              my[item.modifier.name] = selected;
            }
          }
        });
      }
      return my;
    }
  }
};
</script>
